<template>
  <div>
    <a-card title="生产工单">
      <a-row :gutter="[12, 8]">
        <a-col :span="24" style="width: 256px">
          <a-range-picker @change="onChangePicker" />
        </a-col>
        <a-col :span="24" style="width: 240px">
          <a-input v-model="searchForm.search" placeholder="生产工单号, 销售单号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 240px">
          <a-select v-model="searchForm.status" placeholder="生产工单状态" allowClear style="width: 100%" @change="search">
            <a-select-option value="in_plan">计划中</a-select-option>
            <a-select-option value="in_progress">进行中</a-select-option>
            <a-select-option value="completed">已完成</a-select-option>
            <a-select-option value="closed">强制关闭</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" style="width: 120px">
          <a-button-group>
            <a-button @click="printOrder">打印生产工单</a-button>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openCreateModal({})"> 新增生产工单 </a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="tableColumns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :scroll="{ x: 1800 }"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="tableChange"
        >
          <div slot="number" slot-scope="value, item">
            <a @click="detial(item)">{{ value }}</a>
          </div>
          <div slot="sales_order_number" slot-scope="value, item">
            <a v-if="item.sales_order" @click="jumpSalesOrderDetailPage(item)">{{ value }}</a>
            <span v-else>{{ item.sales_number }}</span>
          </div>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button v-if="item.status == 'in_plan'" @click="openCreateModal(item)">编辑</a-button>
              <a-button @click="detial(item)">详情</a-button>
              <!-- <a-button v-if="!item.parent_production_order" @click="openSubProductionOrderModal(item)">子工单</a-button> -->
              <a-button v-if="item.status == 'in_plan'" type="primary" @click="openPublishModal(item)">发布工单</a-button>
              <a-popconfirm v-if="item.status == 'in_progress'" title="确定关闭吗?" @confirm="close(item)">
                <a-button type="primary">关闭工单</a-button>
              </a-popconfirm>
              <a-button v-if="item.status === 'in_progress'" type="primary" @click="openRequirement(item)"> 物料需求 </a-button>
              <a-button
                v-if="item.status === 'in_progress' || item.status === 'completed' || item.status === 'closed'"
                type="primary"
                @click="openStockInModal(item)"
              >
                成品入库
              </a-button>
              <a-popconfirm v-if="item.status == 'in_plan'" title="确定删除吗?" @confirm="destroy(item)">
                <a-button type="danger">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>

          <template slot="expandedRowRender" slot-scope="record, index, indent, expanded">
            <SubProductionOrder :visible="expanded" :item="record" />
          </template>
        </a-table>
      </a-row>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" @create="create" @update="update" />
    <StockInModal v-model="stockInModalVisible" title="成品入库" :item="targetItem" @update="update" />
    <PublishModal v-model="publishModalVisible" :item="targetItem" @update="update" />
    <RequirementModal v-model="requirementModalVisible" :productionOrderItem="targetItem" @cancel="list()" />
    <SubProductionOrderModal v-model="subProductionOrderModalVisible" :items="subProductionOrderItems" />
  </div>
</template>

<script>
import {
  productionOrderList,
  productionOrderDelete,
  productionOrderClose,
  productionOrderSubProductionOrders,
} from "@/api/production";
import { goodsBOMList } from "@/api/goods";
import lodopPrintOrder from "./printOrder";

export default {
  components: {
    FormModal: () => import("./FormModal"),
    StockInModal: () => import("./StockInModal"),
    RequirementModal: () => import("@/components/RequirementModal"),
    PublishModal: () => import("./PublishModal"),
    SubProductionOrderModal: () => import("./SubProductionOrderModal"),
    SubProductionOrder: () => import("./SubProductionOrder"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined, is_parent: true },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "生产工单号",
          dataIndex: "number",
          width: 160,
          scopedSlots: { customRender: "number" },
        },
        {
          title: "销售单号",
          dataIndex: "sales_order_number",
          scopedSlots: { customRender: "sales_order_number" },
        },
        {
          title: "类型",
          dataIndex: "type_display",
          width: 100,
        },
        {
          title: "状态",
          dataIndex: "status_display",
          width: 100,
        },
        {
          title: "产品名称",
          dataIndex: "goods_name",
        },
        {
          title: "产品编号",
          dataIndex: "goods_number",
        },
        {
          title: "计划数量",
          dataIndex: "total_quantity",
          width: 100,
        },
        {
          title: "完成数量",
          dataIndex: "quantity_produced",
          width: 100,
        },
        {
          title: "计划开始时间",
          dataIndex: "planned_start_time",
          width: 180,
        },
        {
          title: "计划完成时间",
          dataIndex: "planned_end_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 300,
          scopedSlots: { customRender: "action" },
        },
      ],
      visible: false,
      targetItem: {},

      stockInModalVisible: false,
      requirementModalVisible: false,
      publishModalVisible: false,
      subProductionOrderModalVisible: false,
      subProductionOrderItems: [],

      selectedRowKeys: [],
      selectedRowItems: [],
    };
  },
  computed: {
    enableSalesManage() {
      return this.$store.state.user.enableSalesManage;
    },
    tableColumns() {
      const tableColumns = [];
      for (const item of this.columns) {
        tableColumns.push(item);
      }
      return tableColumns;
    },
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      productionOrderList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(item) {
      this.items = this.$functions.insertItem(this.items, item);
    },
    update(item) {
      this.items = this.$functions.replaceItem(this.items, item);
    },
    destroy(item) {
      productionOrderDelete({ id: item.id }).then(() => {
        this.$message.success("删除成功");
        this.items = this.$functions.removeItem(this.items, item);
      });
    },
    close(item) {
      productionOrderClose({ id: item.id }).then((data) => {
        this.$message.success("关闭成功");
        this.items = this.$functions.replaceItem(this.items, data);
      });
    },
    detial(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.id } });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0];
      let endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    openCreateModal(item) {
      this.targetItem = { ...item };
      this.visible = true;
    },
    openStockInModal(item) {
      this.targetItem = item;
      this.stockInModalVisible = true;
    },
    openPublishModal(item) {
      this.targetItem = item;
      this.publishModalVisible = true;
    },
    openRequirement(item) {
      this.targetItem = item;
      this.requirementModalVisible = true;
    },
    jumpSalesOrderDetailPage(item) {
      this.$router.push({ path: "/sale/sale_record_detail", query: { id: item.sales_order } });
    },
    openSubProductionOrderModal(item) {
      productionOrderSubProductionOrders({ id: item.id })
        .then((data) => {
          this.subProductionOrderItems = data;
          this.subProductionOrderModalVisible = true;
        })
        .finally(() => {});
    },
    onSelectChange(selectedRowKeys, selectedRowItems) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowItems = selectedRowItems;
    },
    async printOrder() {
      if (this.selectedRowItems.length === 0) {
        this.$message.warning("请选择要打印的单据");
        return;
      }

      const productionOrderItems = [...this.selectedRowItems];
      for (const productionOrderItem of productionOrderItems) {
        productionOrderItem.bom_list = await goodsBOMList({ id: productionOrderItem.goods });
      }

      lodopPrintOrder(productionOrderItems, {
        title: "生产工单",
        headerFields: [
          "product_name",
          "product_number",
          "product_barcode",
          "product_spec",
          "total_quantity",
          "planned_start_time",
          "planned_end_time",
          "creator_name",
          "create_time",
        ],
        tableFields: ["material_name", "material_number", "material_barcode", "material_spec", "drawing_number", "quantity"],
        tableRows: 10,
        tableSortType: "positive",
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
